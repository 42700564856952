import React from 'react'
import SocialShare from './SocialShare'
const asset_url = process.env.REACT_APP_ASSET_URL

const NewsCard = ({ item, handleHover, index, currentIndex }) => {

    const { id, headline, body, thumb_url, published_at } = item
    return (
        <div
            key={id}
            className=' justify-center   h-[54vh] lg:!h-[36rem] rounded-md min-w-[80vw] lg:min-w-[50vw] relative '
            style={{ scrollSnapAlign: 'center' }} >
            {currentIndex == index && <SocialShare handleHover={handleHover} index={index} />}
      
            
             <img src={thumb_url} 
                className="text-center  !w-full  bg-[#80808055] mx-auto max-w-full object-contain block min-h-[40%] lg:h-[80%] my-auto max-h-[70%]" />
            <div className='px-2 py-4  bg-opacity-70 bg-[#f2f2f2] text-black'>
                <p className='font-semibold text-sm'>{headline}</p>
                <p className=' md:block text-xs'>{body}</p>
            </div>
        </div>
    )
}

export default NewsCard
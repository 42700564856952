import React from 'react';
import { Routes, Route } from 'react-router';
import { routes } from './createRoute';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import PageNotFound_new from '../Pages/PageNotFound_new';

const Router = () => {
    return (
        <div>
            <Header key='header-component' />
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        exact
                        path={route.path}
                        element={route.element}
                    >
                    </Route>
                ))}
                <Route path="*" element={<PageNotFound_new />} />
            </Routes>
            <Footer />
        </div>
    );
};


export default Router;
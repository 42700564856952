import accnldp from '../Assets/Images/accnldp.png'
import desco from '../Assets/Images/desco.png'
import amadercity from '../Assets/Images/amadercity.png'
import sanboard from '../Assets/Images/sanboard.png'
import farmer_news from '../Assets/Images/farmer togo.jpeg'
import farmer_news_2 from '../Assets/Images/farmer_news.jpg'
import farmer_news_3 from '../Assets/Images/farmer_.jpg'

import jouav from '../Assets/Images/resources/JOUAV.jpg'

import matrice from '../Assets/Images/resources/MATRICE  300 RTK.jpg'
import mavic_3 from '../Assets/Images/resources/drone-1.jpg'
// import phantom_4 from '../Assets/Images/resources/drone-1.jpg'
import trimble from '../Assets/Images/resources/trimble ux-5.png'

import phantom from '../Assets/Images/resources/phantom.jpg'

import greenseeker from '../Assets/Images/resources/greenseeker-sensor.jpg'
import leica from '../Assets/Images/resources/leica rtk.jpg'
import leica1 from '../Assets/Images/resources/leica rtk 2.png'
import comnav from '../Assets/Images/resources/comnav.jpg'

import sokkia from '../Assets/Images/resources/sokka cx.jpg'

import sokkiab20 from '../Assets/Images/resources/sokkia b20.jpg'

import mipad4 from '../Assets/Images/resources/mipad4.png'

import a7lite from '../Assets/Images/resources/a7 lite.jpg'

import redminote8 from '../Assets/Images/resources/redminote8.jpg'



import redminote9 from '../Assets/Images/resources/redminote9.avif'

import cannonds from '../Assets/Images/resources/canon ds.jpg'


import dellserver from '../Assets/Images/resources/dell server.jpg'

import workstation_photogramettry from '../Assets/Images/resources/workstation photo.webp'

import netgear_readynas from '../Assets/Images/resources/nashousing.jpg'

import benq_zowie from '../Assets/Images/resources/benq zowie.jpg'

import nvidia_geforce from '../Assets/Images/resources/nvidia deforce.png'

import nvidia_goggles from '../Assets/Images/resources/nvidia goggles.webp'


import workstation_gis from '../Assets/Images/resources/workstation gis.png'
import macbookpro from '../Assets/Images/resources/macbook pro.jpg'
import dell_hp_business_series from '../Assets/Images/resources/business series.webp'
import dell_brand_pc from '../Assets/Images/resources/brand pc.jpg'
import hp_plotter from '../Assets/Images/resources/hp plotter.jpg'
import epson_plotter from '../Assets/Images/resources/epson L1800.jpg'
import epsonL130 from '../Assets/Images/resources/epson l130.jpg'
import hpM553 from '../Assets/Images/resources/hp M553.jpg'
import hpM436 from '../Assets/Images/resources/HP LaserJet MFP M436d.png'
import hpM438 from '../Assets/Images/resources/HP LaserJet MFP M438.jpg'
import canon3300 from '../Assets/Images/resources/canon LBP 3300.jpg'
import canonDs from '../Assets/Images/resources/canon ds.jpg'
import hpHdPro from '../Assets/Images/resources/hp hd pro.jpg'
import canonLide from '../Assets/Images/resources/canon lide.jpg'

import erdas from '../Assets/Images/resources/erdas.jpg'
import terrSet from '../Assets/Images/resources/TerrSet.jpg'


import news_ccm from '../Assets/Images/News/ccm.jpg'
import news_job_placement from '../Assets/Images/News/ccm1.jpg'
import basis from '../Assets/Images/News/basis.jpg'
import news_lged from '../Assets/Images/News/contract_lged.jpg'
import news_umcc from '../Assets/Images/News/UMCC.jpg'
import news_cuet from '../Assets/Images/News/news_cuet.jpg'
import news_risk_sens_database from '../Assets/Images/News/inaguration Risk-Sensitive Database for the Core Area of Rangpur and Sylhet District Town.jpg'
import ait from '../Assets/Images/News/Contract AIT news.jpg'
import _3up from '../Assets/Images/News/3up.jpg'
import srdp from '../Assets/Images/News/srdp.jpg'


import service_urd from '../Assets/Images/Services/UrbanRuralDevelopment.jpg'

import service_edu from '../Assets/Images/Services/Education.jpg'
import service_climate from '../Assets/Images/Services/EnvironmentalClimateChange.jpg'
import service_energy from '../Assets/Images/Services/Greenenergyandpower.jpg'
import service_sanitaion from '../Assets/Images/Services/Sanitation.jpg'
import service_smart_city from '../Assets/Images/Services/SmartCities.jpg'
import service_software from '../Assets/Images/Services/SoftwareDevelopment.jpg'
import service_transport from '../Assets/Images/Services/Transportation.jpg'
import service_water from '../Assets/Images/Services/WaterResource.jpg'


export const news_content_list =
    [
        
        {
            headline: `UDD Director Praises Tiller's Innovative Surveying in “Preparation of Development Plan for 3 Upazilas (Pirojpur Sadar, Nesarabad, and Nazirpur Upazila) of Pirojpur District” Project`,
            thumb_url: _3up,
            body: `During a field visit, UDD Director M. Mahmud Ali praised Tiller’s advanced surveying techniques for the 'Preparation of Development Plan for 3 Upazilas of Pirojpur District' project. This  innovative approach will enhance the planning process.`/* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
            status: "active",
            published_at: "2024-09-17",
        },  {
            headline: `UDD Officials Conduct Field Verification for SRDP project`,
            thumb_url: srdp,
            body: `UDD officials conducted a field check for attribute verification and reviewed BM values under the 'Risk-Sensitive Database for Rangpur & Sylhet' project. All BM values were correct, aiding efforts to address vulnerabilities in both districts.`/* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
            status: "active",
            published_at: "2024-09-17",
        },
        {
            headline: `The inauguration of the "Preparation of a Risk-Sensitive Database for the Core Area of Rangpur and Sylhet District Town"`,
            thumb_url: news_risk_sens_database,
            body: `The inauguration of the "Preparation of a Risk-Sensitive Database for the Core Area of Rangpur
             and Sylhet District Town" project took place last Thursday, implemented by the Urban Development Directorate
              (UDD) `/* `under the Ministry of Housing and Public Works in Sylhet.` */ /* `The inauguration occurred at the Sylhet DC office,
             in front of the mural of the Father of the Nation, Bangabandhu Sheikh Mujibur Rahman.` */,
            status: "active",
            published_at: "2024-01-25",
        },
        {
            headline: "PLIS & CCM Workshop",
            thumb_url: news_ccm,
            body: `Development Project Proformance এর প্রণয়ন এবং মূল্যায়ণ আরো সহজ ও কার্যক্ষম করার লক্ষ্যে,Bangladesh Planning Commission এর অধীনে GIZ ফান্ডেড Adaptation to Climate Change into the National and Local Development Planning - II`
            /* `বাংলাদেশ সরকারের প্ল্যানিং কমিশনের সকল সেক্টর ডিভিশন এবং বিভিন্ন মন্ত্রণালয় ও ২০টা এজেন্সীর প্রায় ৪০জন অংশগ্রহণকারীদের ,প্রজেক্টের ২টি মেজর আউটপুট Planning Information System (PLIS) এবং Climate Check Method (CCM) এর সাথে পরিচয় করিয়ে দিতে,বঙ্গবন্ধু আন্তর্জাতিক সম্মেলন কেন্দ্রে গত ১৬ই মার্চ আয়োজন করা হয় “Workshop on Planning Information System (PLIS) and Climate Check Method (CCM)” শীর্ষক একটি ইনসেপশন ওয়ার্কশপ।
            প্রকল্প পরিচালক জনাবা নুসরাত নোমান, যুগ্মপ্রধাণ, প্ল্যানিং কমিশন -এর সভাপতিত্বে ওয়ার্কশপে বিশেষ অতিথি ও প্রধাণ অতিথি হিসাবে উপস্থিত ছিলেন যথাক্রমে জনাব খন্দকার আহসান হোসেন, প্রধান (অতিরিক্ত সচিব) এবং জনাব সত্যজিত কর্মকার, সদস্য (সচিব), কার্যক্রম বিভাগ। অংশগ্রণকারীদের প্রতি প্রকল্প পরিচালক, বিশেষ অতিথি ও প্রধান অতিথির এর পাশাপাশি GIZ এর প্রিন্সিপাল অ্যাডভাইজর জনাব মাহমুদুর রহমান এর স্বাগত বক্তব্যের পর, Tiller -এর চীফ এক্সিকিউটিভ ও উক্ত প্রজেক্টের আইসিটি এক্সপার্ট জনাব তামজিদুল ইসলাম PLIS ও CCM এর উপর পরিচিতিমূলক বক্তব্য রাখেন।
            Tiller এর ট্রেইনিং এক্সপার্ট জনাবা তামান্না সালামের সঞ্চালনায়, ওয়ার্কশপের দ্বিতীয় অংশে প্রকল্পের ক্লাইমেট চেঞ্জ এক্সপার্ট জনাব তৌফিক মহিউদ্দিন অংশগ্রণকারীদের কাছে বিস্তারিতভাবে তুলে ধরেন CCM কী, এর উপযোগিতা ও CCM তৈরির পটভূমি। এ সেশনের পরের অংশে অংশগ্রহণকারীদের কাছ থেকে গ্রুপ ওয়ার্কের মাধ্যমে CCM সংক্রান্ত মতামত, উপদেশ ও সংশোধনী গ্রহণ করা হয়। ওয়ার্কশপের সর্বশেষ সেশনে PLIS সম্পর্কে বিস্তারিত আলোচনা করেন Tiller এর চীফ এক্সিকিউটিভ জনাব তামজিদুল ইসলাম এবং সিনিয়র সফটওয়্যার ইঞ্জিনিয়ার জনাব মোঃ আহসান ইকবাল; যেখানে প্রশ্নোত্তর ও আলোচনার মাধ্যমে তাঁরা অংশগ্রহণকারীদের দেখান কীভাবে PLIS ব্যবহার করে Climate Change এর আলোকে DPP প্রণয়ন ও মূল্যায়ণ খুব সহজেই করা যায়।` */,
            status: "active",
            published_at: "2023-06-21",
        },
        {
            headline: `Job Placement and business mentoring services for skills training graduates in five partner cities`,
            thumb_url: news_job_placement,
            body: `Today's kickoff meeting was held for “𝐉𝐨𝐛 placement and business mentoring support services for skills training
            graduates in five partner cities" project with Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) team.`
            /* `Our honorable CEO sir Mr. Tamzidul Islam, Director of MAWTS Institute of Technology Mr. James Gomes along with the subject matter experts were present in the meeting. 
            #SkillsTraining #JobPlacement #TVET` */,
            status: "active",
            published_at: "2023-06-18",
        },
        /*  {
             headline: "Job Fair at MIST",
             thumb_url: basis,
             body: `We are glad to represent our organization at MIST Job Fair 2022. We believe in equity to recruit the right person in the right place.`,
 
             published_at: "2022-03-31",
             staus: 'hidden'
         }, */
        {
            headline: "Orientation for Leading LGED Officials",
            thumb_url: news_lged,
            body: `Orientation for Leading LGED Officials on “Application of Modern GIS technologies using Drones and Sub-surface/geological Data for 
           Urban and Infrastructure Development Planning”
           #GIS #drone #tiller #giz
           #LGED`,
            status: "active",
            published_at: "2023-01-06",
        }, {
            headline: "Workshop on Photogrammetry technology for 3rd year student of CUET",
            thumb_url: news_cuet,
            body: `Day long workshop on Photogrammetry technology for 3rd year student of CUET.
             Organised by CUET planners alumni association.`,
            status: "active",
            published_at: "2023-11-12",
        }, {
            headline: "Urban Management of Internal migration due to Climate change (UMCC)",
            thumb_url: news_umcc,
            body: `Successfully completed the Urban Management of Internal migration due to Climate change (UMCC)/ Urban Management of Migration and Livelihoods (UMML)
            Thank you BMZ, European Union and GIZ for your support`,
            status: "active",
            published_at: "2023-09-27",
        },

    ];

export const core_competencies =

    [
        {
            name: "Urban & Rural Development",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_urd,
            clientList: [
                { name: "Client A", logo: "https://via.placeholder.com/80" },
                { name: "Client B", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Geological Survey Project 1",
                    clientName: "Client A",
                    startDate: "2023-01-15",
                    endDate: "2023-05-30",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Geological Research Initiative 1",
                    clientName: "Client B",
                    startDate: "2023-03-10",
                    endDate: "2023-09-20",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Water & Sanitaion",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            photo: service_sanitaion,
            clientList: [
                { name: "Client C", logo: "https://via.placeholder.com/80" },
                { name: "Client D", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Urban Renewal Project 1",
                    clientName: "Client C",
                    startDate: "2022-11-20",
                    endDate: "2023-07-05",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Rural Infrastructure Development 1",
                    clientName: "Client D",
                    startDate: "2023-02-18",
                    endDate: "2023-08-25",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Transportation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_transport,
            clientList: [
                { name: "Client E", logo: "https://via.placeholder.com/80" },
                { name: "Client F", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Crop Yield Enhancement Project 1",
                    clientName: "Client E",
                    startDate: "2023-04-05",
                    endDate: "2023-10-15",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Sustainable Farming Initiative 1",
                    clientName: "Client F",
                    startDate: "2023-02-10",
                    endDate: "2023-09-30",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Environmental & Climate Change",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_climate,
            clientList: [
                { name: "Client G", logo: "https://via.placeholder.com/80" },
                { name: "Client H", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Public Transit Expansion Project 1",
                    clientName: "Client G",
                    startDate: "2023-03-20",
                    endDate: "2023-09-10",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Road Infrastructure Improvement 1",
                    clientName: "Client H",
                    startDate: "2023-01-25",
                    endDate: "2023-08-05",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        }, {
            name: "Smart City",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_smart_city,
            clientList: [
                { name: "Client M", logo: "https://via.placeholder.com/80" },
                { name: "Client N", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Environmental Conservation Project 1",
                    clientName: "Client M",
                    startDate: "2023-01-10",
                    endDate: "2023-07-20",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Sustainability Assessment for Client N",
                    clientName: "Client N",
                    startDate: "2023-03-05",
                    endDate: "2023-09-15",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Software Development",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_software,
            clientList: [
                { name: "Client O", logo: "https://via.placeholder.com/80" },
                { name: "Client P", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "River Flow Analysis for Client O",
                    clientName: "Client O",
                    startDate: "2023-02-15",
                    endDate: "2023-08-30",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Hydrological Modeling for Client P",
                    clientName: "Client P",
                    startDate: "2023-04-20",
                    endDate: "2023-10-05",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Energy & Power",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_energy,
            clientList: [
                { name: "Client I", logo: "https://via.placeholder.com/80" },
                { name: "Client J", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "IT Solutions for Client I",
                    clientName: "Client I",
                    startDate: "2023-02-12",
                    endDate: "2023-08-25",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Custom Software Development for Client J",
                    clientName: "Client J",
                    startDate: "2023-04-08",
                    endDate: "2023-10-20",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
        {
            name: "Water Resources",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_water,
            clientList: [
                { name: "Client K", logo: "https://via.placeholder.com/80" },
                { name: "Client L", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Clean Water Access Project 1",
                    clientName: "Client K",
                    startDate: "2023-03-15",
                    endDate: "2023-09-30",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Sanitation Improvement Initiative 1",
                    clientName: "Client L",
                    startDate: "2023-05-01",
                    endDate: "2023-11-10",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },

        {
            name: "Education",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            photo: service_edu,
            clientList: [
                { name: "Client Q", logo: "https://via.placeholder.com/80" },
                { name: "Client R", logo: "https://via.placeholder.com/80" },
            ],
            ourWorks: [
                {
                    projectName: "Public Transit Expansion Project 2",
                    clientName: "Client Q",
                    startDate: "2023-03-20",
                    endDate: "2023-09-10",
                    photo: "https://via.placeholder.com/150",
                },
                {
                    projectName: "Road Infrastructure Improvement 2",
                    clientName: "Client R",
                    startDate: "2023-01-25",
                    endDate: "2023-08-05",
                    photo: "https://via.placeholder.com/150",
                },
            ],
        },
    ];


export const product_list = [
    {
        name: "amader.city",
        full_name: "amader.city",
        description: "Amader City (Smart city solution for both the government officials and citizens; to provide a one-stop service in order to observe, evaluate, and overcome critical issues such as Transportation, Waste Management, Mosquito and Diseases, Tax, Auction, Issue Tracking, Road Construction, Waterlogging, Asset Management.)",
        features: [
            "Real-time data monitoring",
            "Smart transportation solutions",
            "Energy efficiency measures",
            "Citizen engagement tools",
        ],
        photo: amadercity,
        photolist: [
            amadercity,
            amadercity,
            amadercity,
        ],
    },
    {
        name: "plis",
        full_name: "Adaptation to Climate Change into National and Local Development Plan",
        description: `The ACCNLDP II project (the follow-up phase of ACCNLDP I Project) focuses on advanced analysis and provision of climate risk and user-friendly geospatial information, enhancing the capacities of Bangladesh Planning Commission (BPC) to better appraise development project proposals (DPPs) in the context of climate change. The project will enhance the existing tools and processes to make it more comprehensive and beneficial for all the divisions of BPC.
        The project activities, the executing agency, and the sponsoring ministries will be all structured to work in tandem with the other development cooperation projects to avoid duplication and leverage mutual benefit among the projects.Through developing a user-friendly Planning Information System (PLIS) and Climate check Method (CCM) integrated into an easily accessible digital platform, the ACCNLDP II project supports BPC to use climate risk information more increasingly and effectively during national planning and project appraisal processes ensuring more effective investment projects consider climate risks.`,
        features: [
            "Infrastructure development initiatives",
            "Agricultural improvement projects",
            "Education and training programs",
            "Community empowerment activities",
        ],
        photo: accnldp,
        photolist: [
            accnldp,
            accnldp,
            accnldp,
        ],
    },
    {
        name: "Sanitation Dashboard",
        description: `Bangladesh has made remarkable progress in eliminating open defecation. This achievement has spawned 
        ‘second-generation’ sanitation challenges. Rapid urbanization in Bangladesh is degrading the urban environment, 
        public health, and putting additional strain on the country’s already inadequate basic urban service infrastructure.
         Urban waste management services including fecal sludge management and solid waste management are deficient in Bangladesh,
          producing environmental degradation and public health risks. To address these growing concerns, particularly
           for the cities and large towns where the problem is acute, the Government of Bangladesh has taken up a 
           Technical Project Proposal (TPP) named, “Feasibility for Implementation of Solid Waste and Fecal Sludge
            Management System in 53 district Paurashavas and 8 City Corporations”. The integration of FSM with SWM 
            into one package will improve system planning, implementation, operation, and maintenance. Furthermore, 
            the study’s findings will serve as a foundation for the government and development partners to develop
             investment projects and long-term plans for operating and maintaining the systems.`,
        features: [
            "Real-time waste collection tracking",
            "Recycling rate analysis",
            "Environmental impact assessment",
            "Customizable reporting features",
        ],
        photo: sanboard,
        photolist: [
            sanboard,
            sanboard,
            sanboard,
        ],
    },
    {
        name: "DESCO Web-GIS Portal",
        description: `Consultancy services for design, development & installation of multi-platform GIS
         based application for the management of distribution network of DESCO for 
         technical, financial and managerial benefits.`,
        features: [
            "Real-time waste collection tracking",
            "Recycling rate analysis",
            "Environmental impact assessment",
            "Customizable reporting features",
        ],
        photo: desco,
        photolist: [
            desco,
            desco,
            desco,
        ],
    },
    {
        name: "amader.city",
        full_name: "amader.city",
        description: "Amader City (Smart city solution for both the government officials and citizens; to provide a one-stop service in order to observe, evaluate, and overcome critical issues such as Transportation, Waste Management, Mosquito and Diseases, Tax, Auction, Issue Tracking, Road Construction, Waterlogging, Asset Management.)",
        features: [
            "Real-time data monitoring",
            "Smart transportation solutions",
            "Energy efficiency measures",
            "Citizen engagement tools",
        ],
        photo: amadercity,
        photolist: [
            amadercity,
            amadercity,
            amadercity,
        ],
    },
    {
        name: "plis",
        full_name: "Adaptation to Climate Change into National and Local Development Plan",
        description: `The ACCNLDP II project (the follow-up phase of ACCNLDP I Project) focuses on advanced analysis and provision of climate risk and user-friendly geospatial information, enhancing the capacities of Bangladesh Planning Commission (BPC) to better appraise development project proposals (DPPs) in the context of climate change. The project will enhance the existing tools and processes to make it more comprehensive and beneficial for all the divisions of BPC.
        The project activities, the executing agency, and the sponsoring ministries will be all structured to work in tandem with the other development cooperation projects to avoid duplication and leverage mutual benefit among the projects.Through developing a user-friendly Planning Information System (PLIS) and Climate check Method (CCM) integrated into an easily accessible digital platform, the ACCNLDP II project supports BPC to use climate risk information more increasingly and effectively during national planning and project appraisal processes ensuring more effective investment projects consider climate risks.`,
        features: [
            "Infrastructure development initiatives",
            "Agricultural improvement projects",
            "Education and training programs",
            "Community empowerment activities",
        ],
        photo: accnldp,
        photolist: [
            accnldp,
            accnldp,
            accnldp,
        ],
    },
];

export const currentOpenings = [
    {
        title: "Software Engineer",
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        numberOfVacancy: 5,
        salary: "$80,000 - $100,000 per year",
        lastDateOfApply: "2023-11-30",
        requirements: [
            "Bachelor's degree in Computer Science or related field",
            "3+ years of experience in software development",
            "Proficiency in JavaScript, Node.js, and React",
            "Strong problem-solving skills",
        ],
        benefits: [
            "Competitive salary and performance bonuses",
            "Healthcare and dental coverage",
            "Flexible work schedule",
            "Professional development opportunities",
        ],
        jobResponsibilities: [
            "Design, develop, and maintain software applications",
            "Collaborate with cross-functional teams to deliver high-quality software",
            "Troubleshoot and resolve software defects and issues",
            "Stay updated with the latest industry trends and technologies",
        ],
    },
    {
        title: "Associate Urban Planner",
        numberOfVacancy: 3,
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        salary: "$60,000 - $75,000 per year",
        lastDateOfApply: "2023-12-15",
        requirements: [
            "Master's degree in Urban Planning or related field",
            "2+ years of experience in urban planning",
            "Strong knowledge of GIS and urban modeling tools",
            "Excellent communication and presentation skills",
        ],
        benefits: [
            "Competitive compensation package",
            "Opportunity to work on diverse urban planning projects",
            "Collaborative and inclusive work environment",
            "Career growth and development prospects",
        ],
        jobResponsibilities: [
            "Conduct research and analysis on urban planning projects",
            "Prepare and present reports to clients and stakeholders",
            "Participate in urban design and zoning activities",
            "Collaborate with senior planners to develop planning strategies",
        ],
    },
    {
        title: "Chief Admin Officer",
        numberOfVacancy: 1,
        salary: "$100,000 - $120,000 per year",
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        lastDateOfApply: "2023-11-25",
        requirements: [
            "Bachelor's degree in Business Administration or related field",
            "5+ years of experience in administrative leadership roles",
            "Strong organizational and leadership skills",
            "Excellent interpersonal and communication skills",
        ],
        benefits: [
            "Attractive salary package",
            "Comprehensive healthcare coverage",
            "Leadership role in a dynamic organization",
            "Opportunity to shape administrative policies",
        ],
        jobResponsibilities: [
            "Oversee and manage all administrative functions",
            "Develop and implement administrative policies and procedures",
            "Lead a team of administrative professionals",
            "Ensure efficient office operations and facilities management",
        ],
    },
];

export const jobPosts = [
    {
        id: 1,
        jobTitle: 'Software Engineer',
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        vacancy: 5,
        jobContext: 'Join our software engineering team and work on cutting-edge projects.',
        lastDate: '2023-11-01', // You can set a specific date here
        salary: 'Negotiable',
        educationalRequirements: ['Bachelor\'s in Computer Science', 'Master\'s in Software Engineering'],
        professionalRequirements: ['3+ years of experience in web development', 'Strong problem-solving skills'],
        additionalRequirements: ['Familiarity with React and Node.js', 'Excellent communication skills'],
        benefits: ['Health insurance', 'Flexible work hours'],
    },
    {
        id: 2,
        jobTitle: 'Data Analyst',
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        vacancy: 3,
        jobContext: 'We are looking for data analysts to analyze and interpret complex data sets.',
        lastDate: '2023-11-15', // You can set a specific date here
        salary: 'Competitive',
        educationalRequirements: ['Bachelor\'s degree in Statistics or related field'],
        professionalRequirements: ['2+ years of data analysis experience', 'Proficiency in data visualization tools'],
        additionalRequirements: ['Strong analytical and problem-solving skills'],
        benefits: ['Remote work options', 'Professional development opportunities'],
    },
    {
        id: 3,
        jobTitle: 'Marketing Specialist',
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        vacancy: 2,
        jobContext: 'Join our marketing team and help us promote our products and services.',
        lastDate: '2023-12-01', // You can set a specific date here
        salary: 'Based on experience',
        educationalRequirements: ['Bachelor\'s degree in Marketing or related field'],
        professionalRequirements: ['3+ years of marketing experience', 'Digital marketing skills'],
        additionalRequirements: ['Creativity and strong communication skills'],
        benefits: ['Flexible work environment', 'Team-building activities'],
    },
    {
        id: 4,
        jobTitle: 'Graphic Designer',
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        vacancy: 1,
        jobContext: 'We are seeking a creative graphic designer to join our design team.',
        lastDate: '2023-11-20', // You can set a specific date here
        salary: 'Negotiable',
        educationalRequirements: ['Bachelor\'s degree in Graphic Design or related field'],
        professionalRequirements: ['2+ years of graphic design experience', 'Proficiency in design software'],
        additionalRequirements: ['Strong design portfolio'],
        benefits: ['Health and dental benefits', 'Work-life balance'],
    },
    {
        id: 5,
        jobTitle: 'Customer Support Specialist',
        coverPhoto: "https://images.ctfassets.net/pdf29us7flmy/6paStNLWGfZZGqSFJgC04Z/a4c6c39ae1e21c2b83d4f43a2c933ebd/UzwFNcOI.png?w=720&q=100&fm=jpg",
        vacancy: 4,
        jobContext: 'Join our customer support team and assist customers with their inquiries.',
        lastDate: '2023-12-10', // You can set a specific date here
        salary: 'Competitive',
        educationalRequirements: ['High school diploma or equivalent'],
        professionalRequirements: ['1+ year of customer support experience', 'Excellent communication skills'],
        additionalRequirements: ['Empathy and problem-solving abilities'],
        benefits: ['Paid training', 'Opportunity for growth'],
    },
];

export const news_list = [

    {
        id: 1,
        header: {
            cover: { tag: 'agriculture', source: farmer_news, caption: 'Caption/Source/Credit: Ellus rutum tellus pellenteque' },
            title: "Headline: Sample news headline",
            author: 'sadman rahman',
            genre: 'agriculture, farmer',
            date_of_publication: '11/11/2011',
            tags: ['agriculture', 'farmer', 'climate', 'water'],
        },


        sections: [
            {
                order: 1, type: 'full-text', text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. Aliquam faucibus purus in massa tempor nec feugiat nisl. Orci eu lobortis 
            elementum nibh tellus molestie nunc. Eu mi bibendum neque egestas congue quisque egestas. Ut sem viverra aliquet eget sit amet. 
            Viverra tellus in hac habitasse platea dictumst. Mi quis hendrerit dolor magna eget est. Ac orci phasellus
             egestas tellus rutrum tellus pellentesque. Lectus arcu bibendum at varius vel pharetra vel turpis. 
             Vel facilisis volutpat est velit egestas dui id. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et. `
            },
            { order: 2, type: 'full-image', image: { tag: 'field', source: farmer_news_3, caption: 'Caption/Source/Credit: Ellus rutum tellus pellenteque' } },
            { order: 3, type: 'full-quote', text: 'quote quote quote quote' },
            {
                order: 4, type: 'left-text', text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
             eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam faucibus purus in massa tempor nec 
             feugiat nisl. Orci eu lobortis elementum nibh tellus molestie nunc. Eu mi bibendum neque egestas congue quisque egestas.
              Ut sem viverra aliquet eget sit amet. Viverra tellus in hac habitasse platea dictumst. Mi quis 
              hendrerit dolor magna eget est. Ac orci phasellus egestas tellus rutrum tellus pellentesque. Lectus arcu bibendum at 
              varius vel pharetra vel turpis. Vel facilisis volutpat est velit egestas dui id. Pellentesque pulvinar 
              pellentesque habitant morbi tristique senectus et netus et. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
               sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam faucibus purus in massa tempor nec feugiat nisl.
                Orci eu lobortis elementum nibh tellus molestie nunc. Eu mi bibendum neque egestas congue quisque egestas. Ut sem viverra 
                aliquet eget sit amet. Viverra tellus in hac habitasse platea dictumst. Mi quis hendrerit dolor magna eget est. 
                Ac orci phasellus egestas tellus rutrum tellus pellentesque. Lectus arcu bibendum at varius vel pharetra vel turpis.
                 Vel facilisis volutpat est velit egestas dui id. Pellentesque pulvinar pellentesque habitant morbi tristique 
                 senectus et netus et. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Aliquam faucibus purus in massa tempor nec feugiat nisl. Orci 
            eu lobortis elementum nibh tellus molestie nunc. Lorem ipsum dolor sit amet faucibus purus in massa. `,
                image: { tag: 'harvest', source: farmer_news_2, caption: 'Caption/Source/Credit: Ellus rutum tellus pellenteque' },
            },
            {
                order: 5, type: 'full-text', text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. Aliquam faucibus purus in massa tempor nec feugiat nisl. Orci eu lobortis 
            elementum nibh tellus molestie nunc. Eu mi bibendum neque egestas congue quisque egestas. Ut sem viverra aliquet eget sit amet. 
            Viverra tellus in hac habitasse platea dictumst. Mi quis hendrerit dolor magna eget est. Ac orci phasellus
             egestas tellus rutrum tellus pellentesque. Lectus arcu bibendum at varius vel pharetra vel turpis. 
             Vel facilisis volutpat est velit egestas dui id. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus et. `
            },
        ]
    }
]

export const resources_list = {
    'Drone Technology & Lidar': [
        {
            name: 'JOUAV CW-007 UAV (S/N: 10920708)',
            photo: jouav,
            features: [
                'Professional Surveying Drone',
                'Fixed wing VTOL UAV (50 minutes)',
                '42 MP Sony DSLR camera',
                'Resolution 7952×5304',
                'Full frame camera',
                '3 cm V positioning accuracy',
                '1cm+1ppm H positioning accuracy',
                'PPK & IMU on board'
            ]
        }, {
            name: 'DJI Phantom 4 Pro',
            photo: phantom,
            features: [
                '5-direction obstacle avoidance',
                'Excellent camera',
                'Plentiful flight modes',
                'Long flight time',
                'Outstanding range',
            ]
        }, {
            name: 'DJI Mavic 3 Enterprise',
            photo: mavic_3,
            features: [
                '56× Zoom Camera',
                ' RTK Module For Centimeter-Level Precision',
                'Mission Efficiency To New Heights',
                'Highly efficient mapping and surveying missions without the need for GCP',
            ]
        },
        {
            name: 'MATRICE 300 RTK',
            photo: matrice,
            features: [
                'Launched by DJI',
                'Mapping Drone',
                '45 MP Camera',
                'Resolution 960p',
                'Endurance up to 55 minutes',
                'Maximum Altitude 7000m',
                'Landing Accuracy 20 cm'
            ]
        }
    ],
    'RTK GNSS': [
        {
            name: 'Leica GS18T GNSS RTK (S/N: 3615224)',
            photo: leica,
            features: [
                '555 Channels',
                'Tilt compensation: IMU Technology',
                'GPS, GLONASS, BEIDOU, GALILEO, BEIDOU, SBAS',
                'Static Vertical accuracy: 3.0 mm + 0.4 ppm',
                'Real-time Kinematic horizontal accuracy: Hz 8 mm + 1 ppm / V 15 mm + 1 ppm'
            ]
        },
        {
            name: 'Leica GS16 GNSS RTK (S/N: 3713571)',
            photo: leica1,
            features: [
                '555 Channels',
                'Tilt compensation: IMU Technology',
                'GPS, GLONASS, BEIDOU, GALILEO, BEIDOU, SBAS',
                'Real-time Kinematic horizontal accuracy: Hz 8 mm + 1 ppm / V 15 mm + 1 ppm',
                'Static Vertical accuracy: 3.0 mm + 0.4 ppm'
            ]
        },
        {
            name: 'ComNav T300 GNSS (S/N: T3105139, S/N: T3105161)',
            photo: comnav,
            features: [
                'Multipurpose Drone',
                'Max flight time approximately 30 minutes',
                'Obstacle Sensing 5-Direction',
                'Satellite Positioning Systems GPS/GLONASS',
                'Maximum angular speed, S-mode: 250°/s A-mode: 150°/s'
            ]
        }
    ],
    'Survey Equipment': [
        {
            name: 'Sokkia CX-101 Total Station (S/N: GP1587)',
            photo: sokkia,
            features: [
                '1" Accuracy',
                'Fast distance measurement of 0.9s',
                'RED-tech technology reflectorless EDM',
                'Reflectorless operation from 30cm to 500m',
                'Long-range Bluetooth technology',
                'Advanced angle measurement system'
            ]
        },
        {
            name: 'Sokkia-B20 Auto Level',
            photo: sokkiab20,
            features: [
                'Precise, Reliable Automatic Compensator',
                'Ultra-short focus distance of 20cm',
                'Horizontal angles can be read in 1° or 1 gon units.'
            ]
        }
    ],
    'Tablets': [
        {
            name: 'Mi Pad 4 Tablet',
            photo: mipad4,
            features: [
                'Android 8.1 (Oreo), MIUI 9',
                'Qualcomm SDM660 Snapdragon 660 (14 nm)',
                'CPU: Octa-core',
                '13 MP Main Camera and 5 MP Front Camera'
            ]
        },
        {
            name: 'Samsung A7 Lite Tablet',
            photo: a7lite,
            features: [
                'Android 11, One UI 3.1',
                'Mediatek MT8768T Helio P22T (12 nm)',
                'CPU: Octa-core',
                '8 MP Main Camera and 2 MP Front Camera'
            ]
        },
        {
            name: 'Redmi Note 8',
            photo: redminote8,
            features: [
                'Android 9.0 (Pie), MIUI 12',
                'Qualcomm SDM665 Snapdragon 665 (11 nm)',
                'CPU: Octa-core (4x2.0 GHz Kryo 260 Gold & 4x1.8 GHz Kryo 260 Silver)',
                '48 MP Main Camera and 13 MP Front Camera'
            ]
        },
        {
            name: 'Redmi Note 9',
            photo: redminote9,
            features: [
                'Android 10, MIUI 12',
                'MediaTek Helio G85 (12nm)',
                'CPU: Octa-core (2x2.0 GHz Cortex-A75 & 6x1.8 GHz Cortex-A55)',
                '48 MP Main Camera and 13 MP Front Camera'
            ]
        }
    ],
    'Printers & Scanners': [
        {
            name: 'Canon DS126571',
            photo: canonDs,
            features: [
                'Contains FCC ID: AZD221',
                'Contains IC: 498J-221',
                'CIMIT ID: 2014DJ4477',
                'T6i - 24.2MP - 3" Display - DSLR BUNDLE'
            ]
        },
        {
            name: 'HP DesignJet T1708 Plotter',
            photo: hp_plotter,
            features: [
                '44” printer',
                '26 sec/page1, 116 pages per hour1 on A1/D',
                'Up to 2400 x 1200 optimized dpi',
                'HP Thermal Inkjet'
            ]
        },
        {
            name: 'EPSON L1800 (A3 inkjet color printer)',
            photo: epson_plotter,
            features: [
                'Yield of up to 1,500 4R photos',
                'Print speed up to 15ppm',
                'High-yield ink bottles'
            ]
        },
        {
            name: 'EPSON L130 (A4 inkjet color printer)',
            photo: epsonL130,
            features: [
                'Print speed up to 27ppm (Black)',
                'Print speed up to 15ppm (Color)',
                'High-yield ink bottles',
                'Print Resolution: 5760 X 1440dpi',
                'Duplex Print: Manual'
            ]
        },
        {
            name: 'HP Color LaserJet M553',
            photo: hpM553,
            features: [
                'Print only',
                'Print speed letter: Up to 40 ppm (black and color)',
                '2 paper trays (standard); Uses toner with Jet Intelligence',
                'FCC Class A emissions - for use in commercial environments, not residential'
            ]
        },
        {
            name: 'HP LaserJet MFP M436dn',
            photo: hpM436,
            features: [
                'Print, Copy, Scan',
                'MULTITASKING SUPPORTED',
                'As fast as 8.8 sec',
                'Up to 50,000 pages per month'
            ]
        },
        {
            name: 'HP LaserJet MFP M438nda',
            photo: hpM438,
            features: [
                'Multifunction Mono Laser Photocopier',
                'Copy, Print, Scan',
                'Up to 250 Sheets'
            ]
        },
        {
            name: 'Canon LBP 214 DW',
            photo: canonLide,
            features: [
                'Workgroup printer - laser - monochrome',
                'Apple AirPrint, Google Cloud Print, Mopria Print Service',
                'Bond paper, Envelopes, Labels, Plain paper, Recycled paper, Thick paper, Thin paper'
            ]
        },
        {
            name: 'Canon LBP 3300',
            photo: canon3300,
            features: [
                'Workgroup printer - laser – monochrome',
                'Automatic Image Refinement',
                'Envelopes, Plain paper'
            ]
        },
        {
            name: 'HP HD Pro 42-in Scanner',
            photo: hpHdPro,
            features: [
                'Technology: CCD scanning technology',
                'Resolution: 1200 dpi; Enhanced resolution: 9600 dpi',
                'Speed: Color: 15 cm/sec (6 in/sec); Grayscale: 33 cm/sec (13 in/sec)',
                'Media Size: 1067 mm x 21.6 m, 914 mm x 26 m, 610 mm x 37.9 m, 305 mm x 75.76 m (using a color original at 200 dpi and TIFF file format)'
            ]
        },
        {
            name: 'Canon LiDE 400 Scanner',
            photo: canonLide,
            features: [
                'Scanner Element: CIS (Color Contact Image Sensor)',
                'Optical Resolution: 4800 x 4800dpi',
                'Speed: Color A4, 300 dpi: Approx. 8 sec; Grayscale: 1.7 msec/line (300 dpi)',
                'Maximum Document Size: A4 / Letter (216 x 297mm)'
            ]
        }
    ],
    'Computers & Hardware': [
        {
            name: 'Server',
            photo: netgear_readynas,
            features: [
                'Model: Netgear RR231200 ReadyNas',
                'Processor: Intel Atom C3538 Quad Core Processor',
                'RAM: 2GB',
                'Storage: 48 TB (48000 GB)'
            ]
        },
        {
            name: 'Server PC',
            photo: dellserver,
            features: [
                'Model: Dell Server HBPPLN2',
                'Processor: Intel Atom C3538 Quad Core Processor 3.5GHz',
                'RAM: 16GB'
            ]
        },
        {
            name: 'Photogrammetric Workstation',
            photo: workstation_photogramettry,
            features: [
                'Customized Photogrammetry Workstations',
                'Processor: Intel® Core i9 10th gen Processor',
                'RAM: 32GB',
                'Quadro RTX 4000 GPU (8 GB)',
                '64-bit Operating system'
            ]
        },
        {
            name: 'BenQ ZOWIE XL2720 NVDA 3D Vision Monitor',
            photo: benq_zowie,
            features: [
                '3D monitor',
                'Maximum resolution: 1920X1080px',
                'Refresh rate: 144 Hz',
                'Screen size: 27 in'
            ]
        },
        {
            name: 'NVIDIA GeForce 3D Vision IR Emitter',
            photo: nvidia_geforce,
            features: [
                'Receive signal between 1.5 and 15 feet',
                'NVIDIA backlit button',
                '40 hours of stereoscopic 3D',
                'Thumbwheel on the back of IR emitter'
            ]
        },
        {
            name: 'NVIDIA 3D Goggles',
            photo: nvidia_goggles,
            features: [
                'Processor: Intel® Core i9 10th gen Processor',
                'RAM: 32GB',
                'Quadro RTX 4000 GPU (8 GB)',
                '64-bit Operating system'
            ]
        },
        {
            name: 'Stealth S1 3D Mouse',
            photo: nvidia_goggles,
            features: [
                'Receive signal between 1.5 and 15 feet',
                'NVIDIA backlit button',
                '40 hours of stereoscopic 3D',
                'Green and red indicator lights'
            ]
        },
        {
            name: 'GIS Workstation',
            photo: workstation_gis,
            features: [
                'Customized GIS Workstations',
                'Processor: Intel® Core i9 10th gen Processor',
                'RAM: 32GB',
                'Gigabyte RTX 3070 GPU (8 GB)',
                '64-bit Operating system'
            ]
        },
        {
            name: 'Apple MacBook Pro',
            photo: macbookpro,
            features: [
                'Processor: 8-Core Apple M1 chip',
                'RAM: 16 GB',
                'HDD: 1 TB',
                '64-bit Operating system'
            ]
        },
        {
            name: 'Dell and HP Business Series',
            photo: dell_hp_business_series,
            features: [
                'Processor: Intel Core i5',
                'RAM: 8 GB',
                'Intel Integrated HD Graphics 4000',
                '64-bit Operating system'
            ]
        },
        {
            name: 'Brand PC',
            photo: dell_brand_pc,
            features: [
                'Model: Dell OptiPlex 5060',
                'Processor: Intel® Core i7-8700 Processor',
                'RAM: 16 GB',
                '64-bit Operating system'
            ]
        }
    ],
    'Printers & Scanners': [
        {
            name: 'HP DesignJet T1708 Plotter',
            photo: hp_plotter,
            features: [
                '44” printer',
                '26 sec/page1, 116 pages per hour1 on A1/D',
                'Up to 2400 x 1200 optimized dpi',
                'HP Thermal Inkjet'
            ]
        },
        {
            name: 'EPSON L1800 (A3 inkjet color printer)',
            photo: epson_plotter,
            features: [
                'Yield of up to 1,500 4R photos',
                'Print speed up to 15ppm',
                'High-yield ink bottles'
            ]
        },
        {
            name: 'EPSON L130 (A4 inkjet color printer)',
            photo: epsonL130,
            features: [
                'Print speed up to 27ppm (Black)',
                'Print speed up to 15ppm (Color)',
                'High-yield ink bottles',
                'Print Resolution: 5760 X 1440dpi',
                'Duplex Print: Manual'
            ]
        },
    ]
}

export const team_member_home = [
    {

        name: 'Tamzidul Islam',

        emp_id: 1,

        department: 'Senior Management',

        designation: 'Founder & CEO',

        order: 1

    },/*  {
        name: 'Dr.-Ing. K. Z. Hossain Taufique',

        emp_id: 100,

        department: 'Advisors',

        designation: 'Advisor',

        order: 1

    }, */ {
        name: 'Prof. Dr. Ahsanul Kabir',

        emp_id: 101,

        department: 'Consultants',

        designation: <p>Consultant,<br />Transportation, Urban Planning</p>,

        order: 1

    },

    {

        name: 'Md. Anisur Rahman',

        emp_id: 102,

        department: 'Consultants',

        designation: <p>Consultant,<br />Urban Planning,Climate Change and Disaster Management</p>,

        order: 2

    }, {

        name: 'Taufique Mohiuddin',

        emp_id: 62,

        department: 'Consultants',

        designation: <p>Consultant,<br /> Land Management, Climate Change and Policy Expert</p>,

        order: 4

    },

    {

        name: 'SK Mazharul Islam',

        emp_id: 63,

        department: 'Consultants',

        designation: <p>Consultant, <br /> Software Engineering & Architecture, Enterprise Application Integration,</p>,

        order: 3

    },



]

export const employeeFromDatabase = {


    'Senior Management': [{

        name: 'Tamzidul Islam',

        emp_id: 1,

        department: 'Senior Management',

        designation: 'Founder & CEO',

        order: 1

    },

        /* {
    
            name: 'Kaniz Fatema',
    
            emp_id: 2,
    
            department: 'Senior Management',
    
            designation: 'Chief of Administration',
    
            order: 2
    
        } */

    ],

    'Advisors & Consultants': [
        {
            name: 'Dr.-Ing. K. Z. Hossain Taufique',

            emp_id: 100,

            department: 'Advisors',

            designation: 'Advisor',

            order: 1

        },
        {
            name: 'Prof. Dr. Ahsanul Kabir',

            emp_id: 101,

            department: 'Consultants',

            designation: 'Consultant, Transportation Modeling, Urban Planning',

            order: 1

        },

        {

            name: 'Md. Anisur Rahman',

            emp_id: 102,

            department: 'Consultants',

            designation: 'Consultant,Urban Planning,Climate Change and Disaster Management',

            order: 2

        },

        {

            name: 'SK Mazharul Islam',

            emp_id: 63,

            department: 'Consultants',

            designation: 'Consultant,Software Engineering & Architecture, Enterprise Application Integration',

            order: 3

        }, {

            name: 'Taufique Mohiuddin',

            emp_id: 62,

            department: 'Consultants',

            designation: 'Consultant, Land Management, Climate Change and Policy Expert',

            order: 4

        },

        {

            name: 'Tasfin Aziz',

            emp_id: 105,

            department: 'Consultants',

            designation: 'Consultant, Urban Design',

            order: 5

        },

        {

            name: 'Tamanna Salam',

            emp_id: 106,

            department: 'Consultants',

            designation: 'Consultant, Capacity Building, Participatory Planning',

            order: 6

        }

    ],

    'Administration Team': [
        /*  {
 
             name: 'Kaniz Fatema',
 
             emp_id: 2,
 
             department: 'Administration Team',
 
             designation: 'Chief of Administration',
 
             order: 1
 
         }, */

        {

            name: 'Shezan Ahmed',

            emp_id: 37,

            department: 'Administration Team',

            designation: 'Sr. Executive, HR',

            order: 2

        },

        {

            name: 'Razeeb Robert Rozario',

            emp_id: 86,

            department: 'Administration Team',

            designation: 'Finance & Admin Manager',

            order: 3

        },

        {

            name: 'B. M. Foyzur Rahaman',

            emp_id: 10,

            department: 'Administration Team',

            designation: 'Sr. Executive, Admin',

            order: 4

        },

        , {

            name: 'Iftekhar Ahmed',

            emp_id: 7,

            department: 'Administration Team',

            designation: 'Manager, Operation',

            order: 5

        }, {

            name: 'Md. Tanveer Al Mamun',

            emp_id: 16,

            department: 'Office Assistants',

            designation: 'Assistant Admin',

            order: 1

        }

    ],
    'Planning & Design ': [
        {

            name: 'Tanvir Ahmed (Parash)',

            emp_id: 36,

            department: 'Planning & Design ',

            designation: 'Senior Urban Planner',

            order: 3

        },
        {

            name: 'Al-Ferdous Ahmed',

            emp_id: 5,

            department: 'Planning & Design ',

            designation: 'Senior Urban Planner',

            order: 1

        },

        {

            name: 'Mehedi Mudasser',

            emp_id: 77,

            department: 'Planning & Design ',

            designation: 'Senior Urban Planner',

            order: 2

        },

        {

            name: 'Md. Mohaiminul Islam Rifat',

            emp_id: 26,

            department: 'Planning & Design ',

            designation: 'Urban Planner',

            order: 4

        },

        {

            name: 'Tawhidul Islam',

            emp_id: 24,

            department: 'Planning & Design ',

            designation: 'Urban Planner',

            order: 5

        },

        {

            name: 'Sabiha Mahbuba Siza',

            emp_id: 8,

            department: 'Planning & Design ',

            designation: 'Urban Planner',

            order: 6

        },

        {

            name: 'Zakaria Salim',

            emp_id: 25,

            department: 'Planning & Design ',

            designation: 'Associate GIS Analyst',

            order: 7

        },

        {

            name: 'Nahidul Islam',

            emp_id: 21,

            department: 'Planning & Design ',

            designation: 'Associate GIS Analyst',

            order: 8

        },

        {

            name: 'Sultana Asrafi',

            emp_id: 47,

            department: 'Planning & Design ',

            designation: 'Associate Urban Planner',

            order: 9

        },
        {
            name: 'Fahmida Hanif Sumi',
            emp_id: 44,
            department: 'Planning & Design ',
            designation: 'Associate Urban Planner',
            order: 10
        },
        {
            name: 'Woakimul Islam shakil',
            emp_id: 39,

            department: 'Planning & Design ',

            designation: 'Associate Urban Planner, WASH',

            order: 11

        },

        {

            name: 'Moly Podder',

            emp_id: 22,

            department: 'Planning & Design ',

            designation: 'Associate Urban Planner',

            order: 12

        },
        {

            name: 'MD. Sajjad Hossain',

            emp_id: 88,

            department: 'Planning & Design ',
            designation: 'Associate Urban Planner',
            order: 12
        },

        {

            name: 'Asma Amin Rimi',

            emp_id: 51,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 13

        },

        {

            name: 'Fathum Mobin Nafi',

            emp_id: 52,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 14

        },

        {

            name: 'Mrittika Rahman Meem',

            emp_id: 81,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 15

        },

        {

            name: 'Addri Attoza',

            emp_id: 82,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 16

        },

        {

            name: 'Khandokar Tanzim Ahmed',

            emp_id: 83,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 17

        },

        {

            name: 'Fatema tuz Zahra Oyshe',

            emp_id: 49,

            department: 'Planning & Design ',

            designation: 'Jr. Urban Planner',

            order: 18

        },

        {

            name: 'Araf Al Mehedi',

            emp_id: 84,

            department: 'Planning & Design ',
            designation: 'Executive, Communication & Branding',
            order: 19
        },
        {

            name: 'Farhana Akter Akhi',

            emp_id: 87,

            department: 'Planning & Design ',
            designation: 'Documentation & Quality Assurance',
            order: 19
        },

    ],
    'Software Development': [
        {
            name: 'Md. Ahsan Iqbal',

            emp_id: 27,

            department: 'Software Development',

            designation: 'Sr. Software Engineer',

            order: 1

        },

        {

            name: 'Md. Magfur Alam',

            emp_id: 9,

            department: 'Software Development',

            designation: 'Software Engineer',

            order: 2

        },

        {

            name: 'B. M. Tanvir Hossain',

            emp_id: 28,

            department: 'Software Development',

            designation: 'Software Engineer',

            order: 3

        },

        {

            name: 'Mohshin Ahmed Sharif Shaibal',

            emp_id: 57,

            department: 'Software Development',

            designation: 'Software Engineer',

            order: 4

        },

        {

            name: 'Sadman Rahman',

            emp_id: 58,

            department: 'Software Development',

            designation: 'UI/UX Designer',

            order: 5

        },

        {

            name: 'Rafia Sultana',

            emp_id: 85,

            department: 'Software Development',

            designation: 'Jr. Software Engineer',

            order: 6

        }

    ],



    'Photogrammetry (3D Vector Mapping)': [

        {

            name: 'Md. Mamun Miah',

            emp_id: 11,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'Team Lead (3D Vector Mapping)',

            order: 1

        },

        {

            name: 'Md. Yeasin Arafat',

            emp_id: 13,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'Team Lead (3D Vector Mapping)',

            order: 2

        },

        {

            name: 'Rakibul Hasan',

            emp_id: 12,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'QC (3D Vector Mapping)',

            order: 3

        },

        {

            name: 'Nakib Md. Miranuzzaman',

            emp_id: 15,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'QC (3D Vector Mapping)',

            order: 4

        },

        {

            name: 'Md. Robiul Islam',

            emp_id: 46,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 5

        },

        {

            name: 'Roni Das',

            emp_id: 48,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 6

        },

        {

            name: 'Mahadi Hasan Bappy',

            emp_id: 50,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 7

        },

        {

            name: 'Samiul Islam',

            emp_id: 54,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 8

        },

        {

            name: 'Md. Rubel Islam',

            emp_id: 55,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 9

        },

        {

            name: 'Md. Shamim ',

            emp_id: 59,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 10

        },

        {

            name: 'Ekramul kabir',

            emp_id: 60,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 11

        },

        {

            name: 'Md. Rakib Sikder',

            emp_id: 64,

            department: 'Photogrammetry (3D Vector Mapping)',

            designation: 'GIS Photogrammetry Engineer',

            order: 12

        }

    ],

    '2D Vector Mapping': [

        {

            name: 'Tajrian Yesmine Saba',

            emp_id: 68,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 1

        },

        {

            name: 'Homaun Kabir Ridoy',

            emp_id: 69,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 2

        },

        {

            name: 'Shanta Islam',

            emp_id: 71,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 3

        },

        {

            name: 'Rokibul Islam',

            emp_id: 72,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 4

        },

        {

            name: 'Md. Naime Mollik',

            emp_id: 73,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 5

        },

        {

            name: 'H. M. Nurealam Rahman',

            emp_id: 74,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 6

        },

        {

            name: 'Md. Emon Ali',

            emp_id: 75,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 7

        },

        {

            name: 'Md. Asib',

            emp_id: 78,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 8

        }, {

            name: 'Akash Bhuyian',

            emp_id: 80,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 10

        },
        {

            name: 'Rojina Akter Ripa',

            emp_id: 67,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 9

        },
        {

            name: 'Shanto Das',

            emp_id: 0,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 11

        },

        {

            name: 'Rubel Islam',

            emp_id: 0,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 12

        },

        {

            name: 'Rokibul Islam',

            emp_id: 0,

            department: '2D Vector Mapping',

            designation: 'Jr. GIS Engineer',

            order: 13

        },



    ],

    'Office Assistants': [



        {

            name: 'Anjona Begum',

            emp_id: 18,

            department: 'Office Assistants',

            designation: 'Office Assistant',

            order: 2

        },

        {

            name: 'Rotna Begum',

            emp_id: 19,

            department: 'Office Assistants',

            designation: 'Office Assistant',

            order: 3

        },

        {

            name: 'Liton Sheik',

            emp_id: 76,

            department: 'Office Assistants',

            designation: 'Office Assistant',

            order: 4

        },

        {

            name: 'Md. Ripon Hossain',

            emp_id: 61,

            department: 'Office Assistants',

            designation: 'Office Assistant',

            order: 5

        }

    ]

}
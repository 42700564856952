import Contact from "../Pages/Contact";
import Home from "../Pages/Home";
import JoinUs from "../Pages/JoinUs";
import Maintenance from "../Pages/Maintenance";
import PageNotFound from "../Pages/PageNotFound";
import People from "../Pages/People";
import Projects from "../Pages/Projects";
import Services from "../Pages/Services";
import ProjectDetails from "../Pages/ProjectDetails";
// import Admin from "../Pages/Admin";
// import AdminLogin from "../Pages/AdminLogin";
import Career from "../Pages/Career";
import JobPostDetails from "../Pages/JobPostDetails";
import AboutUs from '../Pages/AboutUs';
import News from "../Pages/News";
import Resources from "../Pages/Resources";
import Products from "../Components/Products";
import ProductDetails from "../Pages/ProductDetails";
import Loading from "../Components/Loading";
import ServiceItem from "../Pages/ServiceItem";
import ServiceItemNew from "../Pages/ServiceItem";
import LogoContest from "../Pages/LogoContest";
import AdminLogin from "../Pages/AdminLogin";
import Admin from "../Pages/Admin";



const createRoute = (path, Element) => {
  return { path: path, element: Element }
}


export const routes = [
  createRoute('/', <Home />),
  createRoute('/about', <AboutUs />),
  createRoute('/projects', <Projects />),
  // createRoute('/products', <div className="gap-container"><Products /></div>),
  createRoute('/projects/:id', <ProjectDetails />),
  // createRoute('/products/:id', <ProductDetails />),   
  // createRoute('/news', <News />),
  // createRoute('/people', <People />),
  // createRoute('/people/:id', <People />),
  // createRoute('/joinus', <Career />),
  createRoute('/contact', <Contact />),
  // createRoute('/resources', <Resources />), 
  createRoute('/services', <Services />),
  createRoute('/services/:name', <ServiceItem />),
  // createRoute('/admin', <Admin />),

  // createRoute('/admin/login', <AdminLogin />),
  // createRoute('/career', <Career />),
  // createRoute('/career/:id', <JobPostDetails />),

  // createRoute('/maintenance', <Maintenance />),
  // createRoute('/404', <Loading />),
  createRoute('/logo-contest', <LogoContest />),

  /* SERVICES ROUTES */

  // createRoute('/services/new', <ServiceItemNew />),

  createRoute('/loading', <Loading />)

]; 